import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { FaDownload } from "react-icons/fa"

const Text = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
`

const Box = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  min-width: 250px;
`

const DownloadOptions = styled.div`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Title = styled.h4`
  margin: 15px;
`

const Caption = styled.p`
  width: 100%;
  font-size: 0.7rem;
`

const Button = styled.button`
  width: 100%;
  background-color: #1c365e;
  padding: 7px 10px;
  color: #fff;
  border: none;
  font-weight: bold;
  cursor: pointer;
`

const DownloadPage = () => (
  <Layout>
    <h2 className="text-secondary">Download EVIQUIRE desktop application</h2>
    <br />
    <h1>Choose the option that suits you best:</h1>
    <br />
    <br />

    <DownloadOptions>
      <Box>
        <StaticImage src="../images/windows.png" width={80} alt="Windows" />
        <Title>Eviquire for Windows</Title>
        <Caption>Requires Windows 7 or newer</Caption>
        <Button>
          <FaDownload /> Download For Windows
        </Button>
      </Box>
      <Box>
        <StaticImage src="../images/mac.png" width={80} alt="Mac" />
        <Title>Eviquire for Mac</Title>
        <Caption>Requires 10.9 or newer</Caption>
        <Button>
          <FaDownload /> Download For Mac
        </Button>
      </Box>
      <Box>
        <StaticImage src="../images/ubuntu.png" width={80} alt="Ubuntu" />
        <Title>Eviquire for Linux</Title>
        <Caption style={{ color: "#f9f9f9" }}>....</Caption>
        <Button>
          <FaDownload /> Download For Linux
        </Button>
      </Box>
    </DownloadOptions>

    <br />
    <br />
    <br />

    <h3>Version History:</h3>
    <Text>Current Version 1.0 released April 2022</Text>

    <br />
    <br />
    <br />
  </Layout>
)

export default DownloadPage
